<template>
  <vuestic-widget class="no-padding no-v-padding">
    <el-select v-model="titulo">
      <el-option
        v-for="item in tipoDeFiltro"
        :key="item.titulo"
        :label="item.titulo"
        :value="item.titulo"
      >
      </el-option>
    </el-select>
    <br>
    <h4 class="text-center">Ações por {{titulo}}</h4>
    <table-data-visualisation
      :titulo="objetoselecionado.titulo"
      :campo="objetoselecionado.nome"
      :acoes="acoes"
      v-on:data="filtroPorData"
      :loading="loading"
    />
  </vuestic-widget>
</template>
<script>

import tableDataVisualisation from './tableDataVisualisation.vue';

export default {
  name: 'Dashboard',
  components: {
    tableDataVisualisation,
  },
  data() {
    return {
      loading: false,
      acoes: [],
      acoesPorResponsavel: [],
      acoesPorFerramenta: [],
      acoesPorArea: [],
      titulo: 'Responsável',
      tipoDeFiltro: [
        { nome: 'responsavel', titulo: 'Responsável' },
        { nome: 'ferramenta', titulo: 'Ferramenta' },
        { nome: 'area', titulo: 'Área' },
      ],
    };
  },
  created() {
    this.allGets();
  },
  computed: {
    objetoselecionado() {
      return this.tipoDeFiltro.find(t => t.titulo === this.titulo);
    },
  },
  watch: {
    titulo(novo) {
      this.acoes = this.mudarTipoDaAcao(novo);
    },
  },
  methods: {
    mudarTipoDaAcao(novo) {
      if (novo === 'Responsável') return this.acoesPorResponsavel;
      if (novo === 'Ferramenta') return this.acoesPorFerramenta;
      return this.acoesPorArea;
    },
    async allGets() {
      this.loading = true;
      this.acoesPorResponsavel = await this.getAcao('responsavel');
      this.acoes = this.acoesPorResponsavel;
      this.acoesPorFerramenta = await this.getAcao('ferramenta');
      this.acoesPorArea = await this.getAcao('area');
      this.loading = false;
    },
    async filtroPorData(data, tipo) {
      this.loading = true;
      this.acoesPorResponsavel = await this.getAcao('responsavel', tipo, data);
      this.acoesPorFerramenta = await this.getAcao('ferramenta', tipo, data);
      this.acoesPorArea = await this.getAcao('area', tipo, data);
      this.acoes = this.mudarTipoDaAcao(this.titulo);
      this.loading = false;
    },
    getAcao(filtragem, tipo, data = '') {
      let novaData = '';
      if (data) {
        novaData = `?tipo=${tipo}&min_date=${data[0]}&max_date=${data[1]}`;
      }
      return this.$axios.get(`/api2/acao-${filtragem}/${localStorage.empresaSelecionada}/${novaData}`).then(res => res.data);
    },

  },
};
</script>
